body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONT GILROY */
@font-face {
  font-family: 'Gilroy-BoldItalic';
  src: local('Gilroy-BoldItalic'), url(./styles/fonts/gilroy/Gilroy-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url(./styles/fonts/gilroy/Gilroy-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(./styles/fonts/gilroy/Gilroy-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./styles/fonts/gilroy/Gilroy-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./styles/fonts/gilroy/Gilroy-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./styles/fonts/gilroy/Gilroy-Light.ttf) format('truetype');
}

/* CUSTOM CSS */
/* br {
  content: "";
  margin: 0.25rem;
  display: block;
  font-size: 24%;
} */
